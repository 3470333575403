body {
  color: black;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

svg {
  width: 30%;
}

.main {
   max-width: 705px;
  font-size: 34px;
  font-weight: 900;
  text-transform: uppercase;
   padding: 1.5em;
 }

.main .hint {
  margin-bottom: 0.5em;
}
